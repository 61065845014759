:root {
  --main-red: #CC0000;
  --main-blue: #3B4CCA;
  --main-yellow: #FFDE00;
  --main-red2: #FF0000;
  --main-yellow2: #B3A125;
}

@font-face {
  font-family: 'poke-solid';
  src: url('../src/fonts/pokemon/Pokemon\ Solid.ttf');
}
@font-face {
  font-family: 'poke-hollow';
  src: url('../src/fonts/pokemon/Pokemon\ Hollow.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(var(--main-red), var(--main-yellow), var(--main-blue));
  background-repeat: no-repeat;
  min-height: 100vh;
  height: fit-content;
}

.root {
  padding: 1em;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}