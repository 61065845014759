.App {
  width: 100%;
  margin: 2em 0;
  text-align: center;
  display: flex;
  gap: 2em;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: 'poke-solid';
  letter-spacing: 0.2em;
}

.logo {
  width: 100%;
}

.logo img {
  width: 50%;
  max-width: 300px;
}

.logo img:hover {
  cursor: pointer;
}

.card {
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  border: 1px solid var(--main-red);
  border-radius: 20px;
  background-color: white;
  box-shadow: 5px 5px 10px black;
}

.slideshow-master {
  width: 300px;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
}

.slideshow-container {
  width: 200px;
  overflow-x: hidden;
}

.slideshow {
  width: 200px;
  display: flex;
  transition: 1s ease-in-out;
}


.img {
  width: 200px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.img-container {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated {
  width: 200px;
  height: auto;
  max-height: 180px;
}

.type {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  letter-spacing: 0.5em;
}

.abilities {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
}

.abilities h3 {
  font-weight: normal;
  border-bottom: 1px solid black;
}

.ability-list {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 1em;
}

.ability-list p {
  border: 1px solid var(--main-red);
  border-radius: 5px;
  padding: 5px;
}

.search {
  width: 90%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
}

.search input {
  width: 100%;
  padding: 0.5em;
  border-radius: 10px;
  box-shadow: 3px 3px 10px black;
}

.search button {
  margin-left: 1em;
}

button {
  padding: 0.5em;
  background-color: var(--main-blue);
  color: var(--main-yellow);
  border: none;
  border-radius: 10px;
  font-family: 'poke-solid';
  letter-spacing: 0.3em;
  box-shadow: 5px 5px 10px black;
}

.nav {
  margin: 0 auto;
  width: 90%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
}

.nav button {
  padding: 1em;
  background-color: var(--main-blue);
  color: var(--main-yellow);
  border: none;
}


@media only screen and (min-width: 1000px) {
  button:hover {
    cursor: pointer;
    background-color: #3B4C7A;
  }
  button:active {
    background-color: #2f3d63;
    box-shadow: 2px 2px 5px black;
  }
}